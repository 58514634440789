<template>
  <section class="shipping-info" :class="{ 'shipping-info--marketplace': is_marketplace_environment }">
    <div v-if="loading_zasilkovna" class="shipping-info__loading">
      <Loader />
    </div>
    <p class="shipping-info__title">{{ shipping_info_translations.shipping_option }}</p>

    <div>
      <div
        data-cy="ship-to"
        class="shipping-info__select"
        @click="() => { show_countries = !show_countries; show_states = false; show_rates = false }"
      >
        <div class="shipping-info__select-selected">
          {{ shipping_info_translations.ship_to }}: <b>{{ (selected_country || {}).name || translations.country || $translate("basket.country") }}</b>
          <img
            class="shipping-info__arrow"
            :class="{ 'shipping-info__arrow--open': show_countries}"
            src="/static/icons/arrow.svg"
            alt="arrow"
          />
        </div>
        <transition name="shipping-info">
          <div v-if="show_countries" class="shipping-info__select-items">
            <div
              v-for="{ name, code } in allowed_countries"
              :key="name"
              @click="selected_country = { name, code }"
              class="shipping-info__select-item"
              :class="{ 'shipping-info__select-item--selected': code === (selected_country || {}).code }"
            >
              {{ name }}
            </div>
          </div>
        </transition>
      </div>
      <transition name="shipping-info">
        <div
          v-if="show_states_select"
          class="shipping-info__select"
          @click="() => { show_states = !show_states; show_countries = false; show_rates = false }"
        >
          <div class="shipping-info__select-selected">
            {{ shipping_info_translations.ship_to }}: <b>{{ (selected_country || {}).state_name || translations.state || $translate("basket.state") }}</b>
            <img
              class="shipping-info__arrow"
              :class="{ 'shipping-info__arrow--open': show_states}"
              src="/static/icons/arrow.svg"
              alt="arrow"
            />
          </div>
          <transition name="shipping-info">
            <div v-if="show_states" class="shipping-info__select-items">
              <div
                v-for="{ name, code } in selected_country.code === 'US' ? usa_states : canada_states"
                :key="name"
                @click="selected_country = { ...selected_country, state: code, state_name: name }"
                class="shipping-info__select-item"
                :class="{ 'shipping-info__select-item--selected': code === (selected_country || {}).state }"
              >
                {{ name }}
              </div>
            </div>
          </transition>
        </div>
      </transition>

      <div
        class="shipping-info__select"
        :class="{
          'shipping-info__options--disabled':
            !selected_country ||
            (selected_country && selected_country.code && show_states_select ? !selected_country.state : false)
        }"
        data-cy="shipping-method"
        @click="() => { show_rates = !show_rates; show_countries = false }"
      >
        <div class="shipping-info__select-selected" :class="{ 'shipping-info__select--loading': loading_rates }">
          {{ shipping_info_translations.chosen_method }}: <b>{{ selected_zasilkovna_location ? "Zásilkovna" : chosen_shipping_method }}</b>
          <img
            class="shipping-info__arrow"
            :class="{ 'shipping-info__arrow--open': show_rates}"
            src="/static/icons/arrow.svg"
            alt="arrow"
          />
        </div>
        <transition name="shipping-info">
          <div v-if="show_rates" class="shipping-info__select-items">
            <div
              data-cy="shipping-method-info"
              v-for="{
                amount_local, currency_local, estimated_days,
                shipment, provider, provider_image_75, object_id,
                amount, currency, enable_on_pickup_payment
              } in rates"
              :key="`${amount_local || amount}-${shipment}`"
              @click="select_option({
                price: calculate_discounted_rate(amount_local || amount).price,
                shipment_id: shipment,
                rate_id: object_id,
                country_code: selected_country.code,
                title: provider,
                state: selected_country.state,
                is_custom: use_custom_shipping_methods,
                discount_percentage: rate_discount,
                enable_on_pickup_payment,
                estimated_days
              }, true)"
              class="shipping-info__select-item"
            >
              <div v-if="estimated_days">
                {{
                  shipping_info_translations.estimated_delivery_message |
                  insert_variables_into_string([{ value: estimated_days || "?", type: "estimated_days" }])
                }}
              </div>
              <div v-else>{{ provider }}</div>
              <div v-if="(amount_local || amount) === 0 || calculate_discounted_rate(amount_local || amount).price === 0">
                {{ translations.free }}
              </div>
              <div data-cy="discounted-price" v-else-if="rate_discount && (amount_local || amount) > 0">
                {{ is_currency_symbol(get_currency_for_rate({ currency_local, currency })) ? get_currency_for_rate({ currency_local, currency }) : "" }}
                {{ calculate_discounted_rate(amount_local || amount).price }}
                {{ !is_currency_symbol(get_currency_for_rate({ currency_local, currency })) ? get_currency_for_rate({ currency_local, currency }) : "" }}
                <span>{{ amount_local || amount }}</span>
                <span data-cy="shipping-discount-percentage" v-if="!calculate_discounted_rate(amount_local || amount).max_price_reached">-{{ rate_discount }}%</span>
                <span v-else>-{{ get_currency_for_rate({ currency_local, currency }) }}{{ maximum_shipping_discount }}</span>
              </div>
              <div v-else>{{ get_currency_for_rate({ currency_local, currency }) }} {{ amount_local || amount }}</div>
              <div v-if="provider_image_75"><img :src="provider_image_75" :alt="provider"></div>
              <div v-if="estimated_days"><b>{{ provider }}</b></div>
            </div>
          </div>
        </transition>
      </div>

      <section v-if="is_zasilkovna_enabled" class="shipping-info__other-methods">
        {{ translations.alternate_shipping_methods }}
        <div class="shipping-info__zasilkovna shipping-info__select-selected">
          <div class="shipping-info__zasilkovna-left-column">
            <div class="store-checkbox">
              <input
                ref="zasilkovna_checkbox"
                type="checkbox"
                name="zasilkovna_location"
                id="zasilkovna_location"
                @click="clear_zasilkovna_selection"
              />
              <label for="zasilkovna_location" />
            </div>
            <img src="/static/icons/zasilkovna.png" alt="zasilkovna" @click="toggle_zasilkovna">
            <div class="shipping-info__zasilkovna-location" @click="toggle_zasilkovna">
              <div>{{ translations.location }}:</div>
              <div v-if="!selected_zasilkovna_location">{{ translations.no_location_selected }}</div>
              <div v-else>{{ selected_zasilkovna_location.name }}</div>
            </div>
          </div>
    
          <div
            v-if="
              calculate_discounted_rate(
                get_correct_translation_value(shipping_options.other_methods.zasilkovna, 'price', [language])
              ).price === 0"
            @click="toggle_zasilkovna"
          >
            {{ translations.free }}
          </div>
          <div data-cy="discounted-price" v-else-if="rate_discount && (get_correct_translation_value(shipping_options.other_methods.zasilkovna, 'price', [language], false) || 0) > 0" @click="toggle_zasilkovna">
            {{ is_currency_symbol(currency_symbol) ? currency_symbol : "" }}
            {{ calculate_discounted_rate(get_correct_translation_value(shipping_options.other_methods.zasilkovna, 'price', [language], false) || 0) }}
            {{ !is_currency_symbol(currency_symbol) ? currency_symbol : "" }}
            <span>{{ get_correct_translation_value(shipping_options.other_methods.zasilkovna, 'price', [language], false) || 0 }}</span>
            <span data-cy="shipping-discount-percentage" v-if="!calculate_discounted_rate(get_correct_translation_value(shipping_options.other_methods.zasilkovna, 'price', [language], false) || 0).max_price_reached">-{{ rate_discount }}%</span>
            <span v-else>-{{ currency_symbol }}{{ maximum_shipping_discount }}</span>
          </div>
          <div v-else @click="toggle_zasilkovna">
            {{ is_currency_symbol(currency_symbol) ? currency_symbol : "" }}
            {{ get_correct_translation_value(shipping_options.other_methods.zasilkovna, 'price', [language], false) || 0 }}
            {{ !is_currency_symbol(currency_symbol) ? currency_symbol : "" }}
          </div>
        </div>
      </section>
    </div>
    <div v-if="better_discount" class="shipping-info__better-discount">
      <span v-if="is_marketplace_environment">
        {{ $translate("basket.better_discount_message", {
          needed_amount: `${is_currency_symbol(currency_symbol) ? currency_symbol : ""}
            ${round_to_two_decimals(better_discount.translations[language].minimum_basket_price - products_price(selected_group, language))}
            ${is_currency_symbol(currency_symbol) ? "" : currency_symbol}`,
          off_amount: (maximum_shipping_discount && better_discount.discount_percentage !== '100' ? `${is_currency_symbol(currency_symbol) ? currency_symbol : ""}
              ${maximum_shipping_discount} 
              ${is_currency_symbol(currency_symbol) ? "" : currency_symbol} / ` : "") + `${
            better_discount.translations[language].title || `${better_discount.discount_percentage}% ${shipping_info_translations.off_shipping || ""}`
          }`,
        }) }}
      </span>
      <span v-else>
        {{
          shipping_info_translations.add_amount_for_better_shipping_discount | insert_variables_into_string([
            { value: `${is_currency_symbol(currency_symbol) ? currency_symbol : ""}
              ${round_to_two_decimals(better_discount.translations[language].minimum_basket_price - products_price(selected_group, language))}
              ${!is_currency_symbol(currency_symbol) ? currency_symbol : ""}`, type: "amount" },
            {
              value: (maximum_shipping_discount && better_discount.discount_percentage !== '100' ? `${is_currency_symbol(currency_symbol) ? currency_symbol : ""}
                ${maximum_shipping_discount}
                ${is_currency_symbol(currency_symbol) ? "" : currency_symbol} / ` : "") + `${
                better_discount.translations[language].title || `${better_discount.discount_percentage}% ${shipping_info_translations.off_shipping || ""}`
              }`,
              type: "discount_title"
            }
          ])
        }}
      </span>
    </div>
    <hr>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import Loader from "@/applications/Shared/components/loader/index.vue"
import countries from "../../../../../data/countries"
import canada_states from "../../../../../data/canada_states"
import usa_states from "../../../../../data/usa_states"
import { CLIENT_STORE } from "../../../../Client/constants/other"
import insert_variables_into_string from "../../../../Client/methods/insert_variables_into_string"
import round_to_two_decimals from "../../../../Client/methods/round_to_two_decimals"
import calculate_shipping_rate from "../../../../Client/methods/calculate_shipping_rate"
import get_shipping_rates from "../../../../Client/methods/get_shipping_rates"
import { currency_signs, other_shipping_methods, SHARED_STORE } from "../../../constants/other"
import { MARKETPLACE } from "../../../../Marketplace/constants/other"
import is_currency_symbol from "../../../methods/is_currency_symbol"
import { countries_requiring_states } from "../../../../../data/other_constants"
import { GET_PRODUCT_PRICE } from "../../../stores/Shared/constants"
import get_correct_translation_value from "../../../methods/get_correct_translation_value"

export default {
  components: {
    Loader
  },
  props: {
    selected_group_currency: {
      type: String,
      required: false,
      default: ""
    },
    selected_group_language: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      selected_country: null,
      selected_rate_id: null,
      show_rates: false,
      show_countries: false,
      show_states_select: false,
      loading_rates: false,
      loading_zasilkovna: false,
      show_states: false,
      selected_zasilkovna_location: null,
      rates: [],
      canada_states,
      usa_states
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "shipping_options",
      "translations",
      "selected_language"
    ]),
    ...mapGetters(SHARED_STORE, {
      products_price: GET_PRODUCT_PRICE,
    }),
    ...mapState([
      "is_marketplace_environment",
      "user",
      "$load_script"
    ]),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState(SHARED_STORE, [
      "basket_items",
      "selected_group"
    ]),
    language() {
      return this.selected_group_language || this.selected_language
    },
    currency_symbol() {
      return this.is_marketplace_environment ?
        currency_signs[this.marketplace_currency] :
        (this.translations.currency_symbol || this.translations.currency_code)
    },
    shipping_info_translations() {
      return this.translations.shipping_info ?
        this.translations.shipping_info : {
          shipping_option: this.$translate("basket.shipping_option"),
          ship_to: this.$translate("basket.ship_to"),
          chosen_method: this.$translate("basket.chosen_method"),
          estimated_delivery_message: this.$translate("basket.estimated_delivery_message"),
          add_amount_for_better_shipping_discount: this.$translate("basket.add_amount_for_better_shipping_discount"),
          days_estimate: this.$translate("basket.days_estimate"),
          shipping_method_default: this.$translate("basket.shipping_method_default"),
          off_shipping: this.$translate("basket.off_shipping"),
        }
    },
    chosen_shipping_method() {
      const chosen_rate = this.rates.find(({ object_id }) => object_id === this.selected_rate_id)
      const day_estimate_message = chosen_rate && chosen_rate.estimated_days ?
        `| ${insert_variables_into_string(
          this.shipping_info_translations.days_estimate,
          [{ value: chosen_rate.estimated_days || "?", type: "estimated_days"}]
        )}` : ""

      return chosen_rate ?
        `${chosen_rate.provider} | 
        ${is_currency_symbol(this.get_currency_for_rate(chosen_rate)) ? this.get_currency_for_rate(chosen_rate) : ""}
        ${this.calculate_discounted_rate(chosen_rate.amount_local || chosen_rate.amount).price}
        ${!is_currency_symbol(this.get_currency_for_rate(chosen_rate)) ? this.get_currency_for_rate(chosen_rate) : ""}
        ${day_estimate_message}
        ` : this.shipping_info_translations.shipping_method_default
    },
    product_group_shipping_methods() {
      return this.selected_group?.find(({ shipping_options } = {}) => shipping_options)?.shipping_options
    },
    options() {
      return this.product_group_shipping_methods || this.shipping_options || {}
    },
    settings() {
      return this.options.settings || {}
    },
    use_custom_shipping_methods() {
      return this.options.use_custom_methods || this.options.use_custom_methods === undefined
    },
    custom_methods() {
      return this.options.custom_methods || []
    },
    allowed_countries() {
      let correct_countries

      if (this.use_custom_shipping_methods) {
        const do_all_options_have_allowed_countries = this.custom_methods.every(({ allowed_countries }) => allowed_countries)

        correct_countries = do_all_options_have_allowed_countries ? Array.from(
          new Set(
            this.custom_methods
              .flatMap(({ allowed_countries }) => allowed_countries)
              .map(country => countries.find(({ code }) => code === country))
          )
        ) : countries
      }
      else correct_countries = this.settings.allowed_countries || countries

      return correct_countries.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      })
    },
    maximum_shipping_discount() {
      return this.settings.maximum_shipping_discount || 0
    },
    discounts() {
      return this.options.shipping_discounts || []
    },
    is_free_shipping() {
      return this.discounts.find(({ discount_percentage, translations }) =>
        Number(translations[this.language].minimum_basket_price) <= Number(this.products_price(this.selected_group, this.language)) &&
        discount_percentage === "100"
      )
    },
    rate_discount() {
      const applicable_discounts = this.discounts
        .filter(({ translations }) => Number(translations[this.language].minimum_basket_price) <= Number(this.products_price(this.selected_group, this.language)))
        .map(({ discount_percentage }) => Number(discount_percentage))

      return applicable_discounts.sort((discount_a, discount_b) => discount_b - discount_a)[0] || 0
    },
    better_discount() {
      const better_discounts = this.discounts.filter(({ discount_percentage }) => Number(discount_percentage) > this.rate_discount)

      return better_discounts.sort((discount_a, discount_b) => discount_a.discount_percentage - discount_b.discount_percentage)[0]
    },
    is_zasilkovna_enabled() {
      return this.shipping_options?.other_methods?.zasilkovna?.enabled
    }
  },
  watch: {
    selected_country() {
      this.calculate_rates_if_chosen()
    },
    basket_items: {
      handler() {
        this.select_option(null, true)
        this.calculate_rates_if_chosen()
      },
      deep: true,
    },
    is_free_shipping(val) {
      this.update_free_shipping_state(val)
    },
    language() {
      this.select_option({})
      this.update_selected_country_by_language()
      this.get_rates()
    }
  },
  async mounted() {
    this.update_free_shipping_state(this.is_free_shipping)
    this.update_selected_country_by_language()

    if (this.is_zasilkovna_enabled && !window.Packeta) {
      this.loading_zasilkovna = true
      await this.$load_script("https://widget.packeta.com/v6/www/js/library.js")
      this.loading_zasilkovna = false
    }
  },
  methods: {
    ...mapMutations(CLIENT_STORE, ["update_shipping_option", "update_free_shipping_state"]),
    round_to_two_decimals,
    is_currency_symbol,
    get_correct_translation_value,
    calculate_shipping_rate,
    clear_zasilkovna_selection(e) {
      e.preventDefault();
      e.stopPropagation();

      if (!e.target.checked) {
        this.selected_zasilkovna_location = null
        setTimeout(() => this.$refs.zasilkovna_checkbox.checked = false, 200);
      }
      else this.toggle_zasilkovna()
      return false
    },
    toggle_zasilkovna() {
      window.Packeta.Widget.pick(
        this.shipping_options.other_methods.zasilkovna.api_keys,
        (location) => {
          if (location) {
            this.select_option({
              ...location,
              type: other_shipping_methods.zasilkovna,
              price: get_correct_translation_value(this.shipping_options?.other_methods?.zasilkovna, "price", [this.language], false) || 0
            })
            this.selected_zasilkovna_location = location
            this.$refs.zasilkovna_checkbox.checked = Boolean(location)
          }
        }
      )
    },
    update_selected_country_by_language() {
      const check_for_country = (this.user && this.user.info.country) ? this.user.info.country : this.selected_group_language.toUpperCase()
      const possible_countries = this.allowed_countries.map(({ code }) => code)

      this.selected_country = countries
        .filter(({ code }) => possible_countries.includes(code))
        .find(({ code, alt_code }) => check_for_country === code || check_for_country === alt_code)
    },
    get_currency_for_rate({ currency_local, currency }) {
      return currency_local || currency || this.currency_symbol
    },
    calculate_rates_if_chosen() {
      if (this.selected_country) {
        this.rates = []
        this.select_option({})

        const has_selected_country_states = countries_requiring_states.includes(this.selected_country.code)
        this.show_states_select = has_selected_country_states

        if (!has_selected_country_states) this.get_rates()
        if (has_selected_country_states && this.selected_country.state) this.get_rates()
      }
    },
    calculate_discounted_rate(price) {
      return calculate_shipping_rate(price, this.maximum_shipping_discount, this.rate_discount)
    },
    select_option(shipping_data, clear_other_options) {
      this.selected_rate_id = shipping_data ? shipping_data.rate_id : null
      this.update_shipping_option(shipping_data)
      
      if (clear_other_options && this.$refs.zasilkovna_checkbox) {
        this.selected_zasilkovna_location = null
        this.$refs.zasilkovna_checkbox.checked = false
      }
    },
    async get_rates() {
      if (!this.selected_country) return []

      this.loading_rates = true
      this.rates = this.use_custom_shipping_methods ?
        this.custom_methods
          .filter(({ allowed_countries }) => (allowed_countries || countries.map(({ code }) => code)).includes(this.selected_country.code))
          .map(({ translations, id, estimated_days, enable_on_pickup_payment = false }) => ({
            provider: translations[this.language] ? (translations[this.language].title || "Unknown") : "Unknown",
            amount: translations[this.language] ? (translations[this.language].price || "") : "",
            currency: this.selected_group_currency || this.translations.currency_code,
            object_id: id,
            enable_on_pickup_payment,
            estimated_days
          }))
          .filter(({ amount }) => amount)
        : await get_shipping_rates({ country_code: this.selected_country.code }, this.basket_items, this.translations.currency_code)
      this.loading_rates = false
    }
  },
}
</script>

<style lang="scss" scoped>
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/_marketplace-constants" as *;
@use "../../../../../styles/store_checkbox";

$class: "shipping-info";
.#{$class} {
  position: relative;
  margin: auto;
  text-align: left;

  ::after {
    content: "";
    clear: both;
    display: table;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(var(--body_color_RGB), 0.5);

   .loader-wave {
      position: absolute;
      left: calc(50% - 70px);
      top: calc(50% - 15px);
    }
  }

  &--marketplace {
    .#{$class} {
      &__select-selected {
        border: 1px solid $grey--light;
      }
    }
  }

  &__options--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__other-methods {
    margin: 20px 0 10px;
  }

  &__zasilkovna {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    height: 37px;
    line-height: 37px;
    text-align: right;
    padding-left: 0;
    background-color: $zasilkovna-red;
    color: $pure-white;
    font-weight: bold;

    &-left-column {
      display: grid;
      grid-template-columns: 30px 130px 110px;
      grid-template-areas: "input zasilkovna location";
      text-align: left;
      align-items: center;
      height: 35px;
      margin-top: -2px;

      @media (max-width: $maximum-width) {
        grid-template-columns: 30px 130px 85px;
      }

      @media (max-width: $basket-breakpoint) {
        grid-template-columns: 30px 130px 130px;
      }

      @media (max-width: $mobile) {
        grid-template-columns: 30px 190px;
        grid-template-areas:
          "input zasilkovna"
          "input location";
      }

      @media (max-width: $mobile--small) {
        grid-template-columns: 30px 150px;
      }

      .store-checkbox {
        grid-area: input;
        height: 20px;
        margin-left: 0;
        margin-right: 10px;
      }
    }

    &-location {
      grid-area: location;
      color: #f4f4f5;
      font-size: 10px;
      line-height: normal;

      @media (max-width: $mobile) {
        font-size: 9px;
      }

      div {
        line-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: $mobile) {
          display: inline-block;
          vertical-align: top;
          line-height: 10px;

          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            width: calc(100% - 10px - 50px);
          }
        }
      }
    }

    img {
      grid-area: zasilkovna;
      height: 36px;
      width: fit-content;
      object-fit: contain;
      object-position: center;
      padding-left: 5px;

      @media (max-width: $mobile) {
        padding: 0;
        height: 20px;
      }
    }

    .store-checkbox {
      label {
        border-color: $zasilkovna-red;

        &:after {
          border-color: $zasilkovna-red;
        }
      }
    }
  }

  &__title {
    margin: 0 0 $spacing--tiny;
  }
  
  &__free-shipping {
    color: var(--green_color);
    font-weight: bold;
    text-align: center;
  }

  &__better-discount {
    line-height: normal;
    font-size: $font-size--small;
    color: var(--green_color);
    font-weight: bold;
  }

  &__option {
    padding: 0 10px 0 5px;
    margin-bottom: 10px;
    line-height: 46px;
    background-color: $light-grey;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;

    &--selected {
      background-color: $light-green;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }

    &__name {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__time {
      display: inline-block;
      padding: 0 10px;
      text-align: center;
      vertical-align: middle;
      border-left: 1px solid $pure-black;
      margin-right: -4px;
    }

    &__price {
      display: inline-block;
      padding: 0 10px;
      text-align: center;
      vertical-align: middle;
      border-left: 1px solid $pure-black;
    }

    section {
      display: inline-block;

      &:first-child {
        text-align: left;
      }
      &:last-child {
        float: right;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 12px;
    right: 10px;
    height: 13px;
    width: 13px;
    transform: rotate(90deg);
    object-fit: fill;
    transition: .2s linear;

    &--open {
      transform: rotate(-90deg);
    }
  }

  &__select {
    position: relative;
    margin-bottom: 10px;
    color: $pure-black;
    background-color: $pure-white;
    border-radius: 3px;

    &--loading {
      opacity: 0.5;

      &:after {
        display: block;
        top: 0;
        left: 0;
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        transform: translateX(-100%);
        background: linear-gradient(90deg, transparent, var(--body_color), transparent);
        animation: skeleton-loading 1.5s infinite;
      }
    }

    &-selected {
      padding: 0 10px;
      cursor: pointer;
      overflow: hidden;
      transition: .2s linear;
      border: 1px solid var(--light_grey);
      border-radius: 3px;
      font-size: 14px;
    }

    &-item {
      padding: 10px;
      font-size: 13px;
      line-height: 20px;
      border-bottom: 1px solid var(--background_heavy);
      transition: .2s linear;
      cursor: pointer;

      div {
        position: relative;
        display: inline-block;
        width: calc(50% - 3px);
        height: 20px;
        box-sizing: border-box;
        object-fit: cover;
        text-align: left;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
          position: absolute;
          left: 0;
          bottom: 0;
        }

        span {
          display: inline-block;
          font-size: 12px;
          vertical-align: bottom;

          &:first-child {
            text-decoration: line-through;
            background-color: transparent !important;
            color: $grey !important;
            margin: 0 4px !important;
            padding: 0 !important;
            font-weight: normal !important;
            font-size: 12px !important;
          }
          &:last-child {
            padding: 0 4px;
            border-radius: 3px;
            background: $red-color-light;
            color: $red-color;
            font-size: 11px;
            font-weight: 600;
          }
        }

        &:nth-child(even) {
          text-align: right;
        }
      }

      &:hover, &--selected {
        background-color: $light-grey;
      }

      &:last-child {
        border: none;
      }
    }
    &-items {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 200px;
      z-index: 101; // To be higher than Paypal buttons
      background-color: $pure-white;
      overflow: auto;
      box-shadow: 0px 10px 15px -9px $shadow-color;
    }
  }

  &-enter-active, &-leave-active {
    transition: .2s;
  }
  &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-20px);
  }

  @keyframes skeleton-loading {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
